import React from 'react'

import Photo from '@components/photo'

const Gallery = ({ photos = [] }) => {
  return (
    <div className="gallery">
      {photos?.map((item, key) => {
        return (
          <div
            key={key}
            className={`relative ${
              item.isFullwidth ? 'col-span-full' : 'col-span-1'
            }`}
          >
            {item.photo && <p className="gallery--alt">{item.photo.alt}</p>}
            <Photo
              photo={item.photo}
              className="z-0"
              layout={`gallery${item.isFullwidth ? '-full' : '-half'}`}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Gallery
