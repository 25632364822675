import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { buildSrc } from '@lib/helpers'
import { countryList } from 'studio/schemas/documents/artist'
import CustomLink from '@components/link'

const ArtistsOverview = ({ artists }) => {
  // this line is used to rerender the component when the hash changes
  const router = useRouter()
  function getHash() {
    typeof window === 'undefined' ? '' : window.location.hash.replace('#', '')
  }
  const hash = getHash()
  const [currentSectionID, setCurrentSectionID] = useState(hash)
  // update the currentSectionID when the hash changes
  if (hash && hash !== currentSectionID) setCurrentSectionID(hash)

  const alphabets = []
  const artistGroups = artists
    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    .reduce((acc, artist) => {
      const firstLetter = artist.title.charAt(0).toLowerCase()
      if (!alphabets.includes(firstLetter)) {
        alphabets.push(firstLetter)
      }
      if (!acc[firstLetter]) acc[firstLetter] = []
      acc[firstLetter].push(artist)
      return acc
    }, {})

  const alphabetsRef = useRef([])
  useEffect(() => {
    function onScroll() {
      const scrollY = window.scrollY
      const currentSection = alphabetsRef.current.find(
        (el) => el.offsetTop > scrollY
      )
      if (currentSection) {
        const letter = currentSection.id
        if (currentSectionID !== letter) {
          // shallow router.replace not working
          history.replaceState(null, null, `#${letter}`)
          setCurrentSectionID(letter)
        }
      }
    }

    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [currentSectionID])

  return (
    <section className="collection-hero !mb-24">
      <div className="sidebar pr-12 text-sm !self-stretch">
        <div className="sticky top-8 left-0">
          <div className="h-[1px] bg-gray mb-8"></div>
          <div>
            <div className="px-3 leading-4 mb-8 font-medium">
              Sort by alphabet
            </div>
            <ul className="flex flex-col leading-7">
              {alphabets.map((letter) => (
                <li key={letter}>
                  <a
                    href={`#${letter}`}
                    className={cx(
                      'px-3 w-36 block rounded-full hover:no-underline',
                      currentSectionID === letter && 'bg-gray-muted font-medium'
                    )}
                  >
                    <span className="flex justify-center w-[13px]">
                      {letter.toUpperCase()}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div style={{ flexGrow: 1 }}>
        <ul>
          {Object.keys(artistGroups).map((letter, index) => (
            <li
              key={letter}
              id={letter}
              className="grid grid-cols-1 gap-y-4 md:gap-y-0 md:grid-cols-24 border-t-[1px] border-t-solid border-t-gray py-8"
            >
              <div className="col-span-4 h-full">
                <a
                  id={letter}
                  ref={(el) => (alphabetsRef.current[index] = el)}
                  className="text-5xl w-9 flex justify-center leading-tight hover:no-underline sticky top-4"
                >
                  {letter.toUpperCase()}
                </a>
              </div>
              <ul className="grid grid-cols-1 lg:grid-cols-3 col-span-20 gap-y-10">
                {artistGroups[letter].map((artist) => (
                  <li key={artist.title}>
                    <CustomLink
                      link={{ page: artist }}
                      className="flex w-full items-center"
                    >
                      <div
                        className="rounded-full h-15 w-15 overflow-hidden mr-5"
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        {artist.photo ? (
                          <img
                            src={
                              artist.photo.asset
                                ? buildSrc(artist.photo, {
                                    width: 60,
                                    height: 60,
                                  })
                                : artist.photo
                            }
                            width={60}
                            height={60}
                            className="object-cover h-full w-full"
                          />
                        ) : (
                          <div className="h-full w-full"></div>
                        )}
                      </div>
                      <div className="font-sm leading-[15px] flex flex-col items-start justify-center">
                        <div className="font-medium">{artist.title}</div>
                        <div className="font-normal">
                          {countryList.find((c) => c.value === artist.country)
                            ?.title ?? ''}
                        </div>
                      </div>
                    </CustomLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default ArtistsOverview
