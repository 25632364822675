import React from 'react'
import Link from 'next/link'
import cx from 'classnames'

import CustomLink from '@components/link'

const BreadcrumbItem = ({ path }) => {
  if (!path) return null
  return (
    <>
      {' '}
      / <CustomLink link={{ page: path.page }}>{path.title}</CustomLink>
    </>
  )
}

const Breadcrumb = ({ data, className }) => {
  const { type, category, parentPage } = data

  let paths = []
  switch (type) {
    case 'story':
    case 'storyCategory':
      if (parentPage) paths = [{ title: parentPage.title, page: parentPage }]
      break
    case 'product':
    case 'collection':
      if (category) paths = [{ title: category.title, page: category }]
      break
    default:
      paths = [{ ...data, page: data }]
      break
  }

  return (
    <div className={cx('breadcrumbs', className)}>
      <Link href="/" scroll={false}>
        Home
      </Link>
      {paths.map((path, i) => (
        <BreadcrumbItem key={i} path={path} />
      ))}{' '}
      / ..
    </div>
  )
}

export default Breadcrumb
