import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'
import useSWR from 'swr'
import cx from 'classnames'
import { m } from 'framer-motion'

import {
  centsToPrice,
  hasObject,
  isBrowser,
  CUSTOM_PRODUCTION,
  formatOption,
  isPrimaryStore,
  getCurrency,
} from '@lib/helpers'

import NotFoundPage from '@pages/404'
import Layout from '@components/layout'
import AccordionList from '@components/accordion-list'
import Photo from '@components/photo'
import Gallery from '@components/gallery'
import CustomLink from '@components/link'
import {
  ProductActions,
  ProductPrice,
  ProductWishlistToggle,
} from '@components/product'
import Icon from '@components/icon'
import Slider from '@components/slider'
import { trackProductViewed } from '@lib/gtm'
import Breadcrumb from '@components/breadcrumb'
import Divider from '@components/modules/divider'
import ProductSlider from '@components/modules/product-slider'
import { useSiteContext } from '@lib/context'

const getSizeValue = (options) => {
  return options.find((o) => o.name === 'Size')?.value
}

// setup our inventory fetcher
const fetchInventory = (url, id) =>
  axios
    .get(url, {
      params: {
        id: id,
      },
    })
    .then((res) => res.data)

const ProductPage = ({ data }) => {
  const router = useRouter()
  const { currentCurrency } = useSiteContext()

  if (!router.isFallback && !data) return <NotFoundPage statusCode={404} />

  // extract our data
  const { site, page } = data
  const { countryCode } = currentCurrency || {}

  // set our Product state
  const [product, setProduct] = useState(page.product)
  // find the default variant for this product by matching against the first product option
  const defaultVariant = page.product.variants?.find((v) => {
    const option = {
      name: page.product.options?.[0]?.name,
      value: page.product.options?.[0]?.values[0],
      position: page.product.options?.[0]?.position,
    }
    return hasObject(v.options, option)
  })

  const defaultVariantID = defaultVariant?.id ?? page.product.variants[0]?.id
  if (!defaultVariantID) return null

  const [activeVariantID, setActiveVariantID] = useState()

  // check our product inventory is still correct
  const { data: productInventory } = useSWR(
    ['/api/shopify/product-inventory', page.product.id],
    (url, id) => fetchInventory(url, id),
    { errorRetryCount: 3 }
  )

  let descriptions = [
    product.description,
    ...(product.otherDescriptions || []).map((d, key) => ({
      ...d,
      id: key,
    })),
  ].filter((d) => d?.content)

  const recommendedProductSlugs = []

  const recommendedData = {
    source: 'product',
    label: 'Recommended',
    title: 'Might also like',
    products: [
      ...(page.relatedProducts ?? []),
      ...(page.productsByArtist ?? []),
      ...(page.productRecommendations ?? []),
    ]
      .filter((product) => {
        if (recommendedProductSlugs.includes(product.slug)) {
          return false
        }
        recommendedProductSlugs.push(product.slug)
        return true
      })
      .slice(0, 4),
  }

  const hasCustomProduction = () => {
    return product.options?.some((o) =>
      o.values.some((v) => v.toLowerCase().indexOf(CUSTOM_PRODUCTION) > -1)
    )
  }

  const getActiveVariant = () => {
    return product.variants.find((v) => v.id == activeVariantID)
  }

  const getActivePhoto = () => {
    return getActiveVariant()?.photo || product?.photo
  }

  // rehydrate our product after inventory is fetched
  useEffect(() => {
    if (page.product && productInventory) {
      setProduct({
        ...page.product,
        inStock: productInventory.inStock,
        lowStock: productInventory.lowStock,
        variants: [
          ...page.product.variants.map((v) => {
            const newInventory = productInventory.variants.find(
              (nv) => nv.id === v.id
            )

            return newInventory
              ? {
                  ...v,
                  ...newInventory,
                  restrictFrameTypes: page.product.restrictFrameTypes,
                  category: page.product.category,
                  photo: v.photo || product.photo,
                }
              : v
          }),
        ],
      })
    }
  }, [page.product, productInventory])

  useEffect(() => {
    // Track product viewed
    trackProductViewed(defaultVariant)
  }, [])

  const gallery = product?.gallery || []

  return (
    <>
      {!router.isFallback && (
        <Layout
          site={site}
          page={page}
          schema={getProductSchema(product, defaultVariantID, site)}
        >
          <div className={cx('product', `is-${product?.category?.slug}`)}>
            <Slider
              className={cx('product--mobile-gallery', {
                'is-slider': gallery.length > 0,
              })}
            >
              {product?.photo && (
                <div className="photo-dropshadow is-lg">
                  <img loading="lazy" src={getActivePhoto()} />
                </div>
              )}
              {gallery.map(
                (item, key) =>
                  item.photo && (
                    <div className="slider--item relative" key={key}>
                      {item.photo && (
                        <p className="gallery--alt">{item.photo.alt}</p>
                      )}
                      <Photo
                        photo={item.photo}
                        layout={
                          product.category?.slug === 'frames'
                            ? 'product-frames'
                            : 'product'
                        }
                      />
                    </div>
                  )
              )}
            </Slider>
            <div className="product--content">
              <div className="flex">
                <div className="product--details sidebar">
                  <Breadcrumb data={product} className="is-desktop" />
                  {descriptions.length > 0 ? (
                    <AccordionList
                      data={{ items: descriptions }}
                      defaultAccordion="about"
                    />
                  ) : (
                    <p>This product has no description</p>
                  )}
                </div>
                <div className="product--photo photo-dropshadow flex-grow is-lg">
                  <img
                    loading="lazy"
                    src={getActivePhoto()}
                    // layout={
                    //   product.category.slug === 'frames'
                    //     ? 'product-frames'
                    //     : 'product'
                    // }
                  />
                </div>
              </div>
              <Gallery photos={gallery} />
            </div>
            <div className="product--variants sidebar is-sticky">
              <div>
                <Breadcrumb data={product} className="is-mobile" />
                <h1 className="text-lg leading-5 font-medium">
                  {product.title}
                </h1>
                {product?.artist && (
                  <p className="text-2xl">
                    By{' '}
                    <CustomLink
                      link={{ page: product.artist }}
                      className="underline"
                    >
                      {product.artist.title}
                    </CustomLink>
                  </p>
                )}

                <div className="product--options">
                  <div className="product--options--item">
                    <div className="product--options--item--content">
                      <span>Wishlist</span>
                      <ProductWishlistToggle product={product} />
                    </div>
                  </div>
                  {countryCode === 'DK' && (
                    <div className="product--options--item">
                      <div className="product--options--item--content">
                        <span>Ønskeskyen</span>
                        <div className="product--actions">
                          <button
                            className="btn is-onskeskyen"
                            id="gowishRoundedButton"
                            type="button"
                          >
                            Tilføj
                            <img
                              className="w-4"
                              src="https://storage.googleapis.com/gowish-button-prod/img/icons/white_cloud.svg"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <Variants product={product} setActive={setActiveVariantID} />
                </div>
              </div>
              <div>
                {hasCustomProduction() && (
                  <p className="product--custom-production">
                    * {page.disclaimers.customProductionsText}
                  </p>
                )}
                {product.category.showShippingDisclaimer && (
                  <p className="product--size-warning">
                    {page.disclaimers.noWorldwideShippingText}
                  </p>
                )}
              </div>
            </div>
          </div>
          <Divider data={{ showStroke: true, space: 'lg' }} />
          <ProductSlider data={recommendedData} />
        </Layout>
      )}
    </>
  )
}

const Variants = ({ product: { options, variants, title }, setActive }) => {
  const [activeGroup, setActiveGroup] = useState()

  const accordionAnim = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    closed: {
      opacity: 0,
      height: 0,
    },
  }

  const collapseOptions = () => {
    setActiveGroup(null)
  }

  useEffect(() => {
    if (isBrowser && activeGroup) {
      window.addEventListener('click', collapseOptions)
      return () => {
        window.removeEventListener('click', collapseOptions)
      }
    }
  }, [activeGroup])

  if (options.length === 0) return null
  if (options.length > 1)
    // Multiple options
    return options[0]?.values.map((option) => {
      const variant = variants.find((v) =>
        v.options.some((o) => o.value === option)
      )

      const isActive = () => {
        return activeGroup === variant
      }
      return (
        <div
          className={cx('product--options--item', {
            'is-active': isActive(),
          })}
        >
          <div
            className="product--options--item--content"
            onMouseOver={() => setActive(variant.id)}
            onMouseOut={() => setActive(null)}
          >
            {option}
            {isActive() ? (
              <span
                className="text-sm underline text-gray-50 leading-7"
                // onClick={showTypeInfo}
              >
                Type information
              </span>
            ) : (
              <>
                {!!variant?.comparePrice && <OldPrice item={variant} />}
                <button
                  type="button"
                  className="pill"
                  onClick={() => setActiveGroup(variant)}
                >
                  <ProductPrice item={variant} />{' '}
                  <Icon name="Plus" className="w-3" color="white" />
                </button>
              </>
            )}
          </div>
          <m.div
            className="product--options--list"
            initial={'closed'}
            animate={isActive() ? 'open' : 'closed'}
            variants={accordionAnim}
            transition={{
              duration: 0.5,
              ease: [0.19, 1.0, 0.22, 1.0],
            }}
          >
            {options[1].values.map((suboption, i) => {
              const subvariant = variants.find(
                (v) =>
                  v.options.some((o) => o.value === suboption) &&
                  v.options.some((o) => o.value === option)
              )
              return (
                <VariantItem
                  key={i}
                  value={suboption}
                  name={options[1].name}
                  variant={subvariant}
                  setActive={setActive}
                />
              )
            })}
          </m.div>
        </div>
      )
    })

  // Single option
  return options[0]?.values.map((option, i) => {
    const variant = variants.find((v) =>
      v.options.some((o) => o.value === option)
    )
    if (!variant) return null
    return (
      <VariantItem
        key={i}
        // If the option is 'Default Title', use the product title instead
        value={options[0].name === 'Title' && option === 'Default Title' ? title : option}
        name={options[0].name}
        variant={variant}
        setActive={setActive}
      />
    )
  })
}

const VariantItem = ({ value, name, variant, setActive }) => {
  return (
    <div
      className="product--options--item"
      onMouseOver={() => setActive(variant.id)}
      onMouseOut={() => setActive(null)}
    >
      <div className="product--options--item--content">
        <span>{formatOption({ value, name })}</span>
        <Actions variant={variant} />
      </div>
    </div>
  )
}

const OldPrice = ({ item }) => {
  return (
    <span className="pill is-light product--old-price">
      <ProductPrice item={item} comparePrice={true} />
    </span>
  )
}

const Actions = ({ variant }) => {
  return (
    <div className="flex items-center">
      {!!variant?.comparePrice && <OldPrice item={variant} />}
      <ProductActions activeVariant={variant} className="pill">
        <span className="pill--hover">ADD</span>
        <ProductPrice item={variant} />{' '}
        <Icon name="BasketSolid" className="w-3" color="white" />
      </ProductActions>
    </div>
  )
}

function getProductSchema(product, activeVariantID, site) {
  if (!product) return null

  const router = useRouter()
  const { query } = router

  const variant = product.variants.find((v) => v.id == activeVariantID)

  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: product.title,
    image: product.photo,
    description: product.description,
    price: centsToPrice(query.variant ? variant.price : product.price),
    sku: query.variant ? variant.sku : product.sku,
    offers: {
      '@type': 'Offer',
      url: `${site.rootDomain}/products/${product.slug}${
        query.variant ? `?variant=${variant.id}` : ''
      }`,
      availability: query.variant
        ? `http://schema.org/${variant.inStock ? 'InStock' : 'SoldOut'}`
        : `http://schema.org/${product.inStock ? 'InStock' : 'SoldOut'}`,
      price: (query.variant ? variant.price : product.price) / 100,
      priceCurrency: isPrimaryStore() ? 'EUR' : 'USD',
    },
    brand: product?.artist ? product?.artist?.title : 'Paper Collective',
  }
}

export default ProductPage
