import React from 'react'
import Breadcrumb from './breadcrumb'

const Introduction = ({ page, hasSidebar = false }) => {
  if (!page) return null

  return (
    <div className="collection-hero">
      {hasSidebar && <div className="sidebar" />}
      <div>
        <Breadcrumb data={page} />
        {page?.title && (
          <h1 className="text-3xl md:text-5xl uppercase">{page.title}</h1>
        )}
        {page?.seo?.metaDesc && (
          <p className="mt-10 md:max-w-2xl">{page.seo.metaDesc}</p>
        )}
      </div>
    </div>
  )
}

export default Introduction
