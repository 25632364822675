import React from 'react'
import { useRouter } from 'next/router'
import ArtistsOverview from '@components/artists-overview'

import {
  getPage,
  getProduct,
  getCategory,
  getCollection,
  getAllDynamicSlugs,
} from '@data'
import { getDiscountPercentage } from '@lib/shopify'
import { getPageSlugs } from '@lib/helpers'

import NotFoundPage from '@pages/404'

import Layout from '@components/layout'
import Product from '@components/pages/product'
import Products from '@components/pages/products'
import { Module } from '@components/modules'
import Introduction from '@components/introduction' 

const Container = ({ centered, children }) => {
  if (centered)
    return (
      <div className="flex">
        <div className="sidebar desktop-only" />
        <div className="lg:mx-7 w-full">{children}</div>
        <div className="sidebar desktop-only" />
      </div>
    )
  return children
}

const Page = ({ type, data }) => {
  const { site, page } = data ?? {}
  if (!page || !site) return <NotFoundPage statusCode={404} />

  switch (type) {
    case 'product':
      return <Product data={data} />
    case 'category':
    case 'collection':
      return <Products data={data} />
    default:
      return (
        <Layout site={site} page={page}>
          <Container centered={page?.centered}>
            <>
              {page?.showIntro && (
                <Introduction
                  page={page}
                  hasSidebar={page.slug === 'artists'}
                />
              )}
              {page.slug === 'artists' && (
                <ArtistsOverview artists={page.artists} />
              )}
              {(page.modules || [])?.map((module, key) => (
                <Module key={key} module={module} />
              ))}
            </>
          </Container>
        </Layout>
      )
  }
}

export async function getStaticProps({ params, preview, previewData }) {
  // Figure out which type of page this is
  let allPages = await getAllDynamicSlugs()

  const page = allPages.find(
    (p) => getPageSlugs(p).join('/') === params.slug.join('/')
  )


  let data = {}

  switch (page?.type) {
    case 'product':
      data = await getProduct(page.slug, {
        active: preview,
        token: previewData?.token,
      })
      break
    case 'collection':
      data = await getCollection(page.slug, {
        active: preview,
        token: previewData?.token,
      })
      break
    case 'category':
      data = await getCategory(page.slug, {
        active: preview,
        token: previewData?.token,
      })
      break
    default:
      data = await getPage(page?.slug, {
        active: preview,
        token: previewData?.token,
      })
      break
  }

  // Get discount percentage
  if (data?.site?.shop?.framePickerDiscountCode) {
  const discountPercentage = await getDiscountPercentage(data?.site?.shop?.framePickerDiscountCode)
  if (discountPercentage) {
    data.site.shop.framePickerDiscountPercentage = discountPercentage
    }
  }

  return {
    props: {
      data,
      type: page?.type || null,
    },
  }
}

export async function getStaticPaths() {
  const allPages = await getAllDynamicSlugs()
  const pagesWithoutProducts = allPages?.filter((page) => page.type !== 'product')

  return {
    paths:
      pagesWithoutProducts?.map((page) => ({
        params: {
          slug: getPageSlugs(page),
        },
      })) || [],
    fallback: 'blocking',
  }
}

export default Page
