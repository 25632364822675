import React from 'react'
import { Users } from 'phosphor-react'
import customImage from '../../lib/custom-image'

export const countryList = [
  {
    title: 'Afghanistan',
    value: 'AF'
  },
  {
    title: 'Albania',
    value: 'AL'
  },
  {
    title: 'Algeria',
    value: 'DZ'
  },
  {
    title: 'American Samoa',
    value: 'AS'
  },
  {
    title: 'Andorra',
    value: 'AD'
  },
  {
    title: 'Angola',
    value: 'AO'
  },
  {
    title: 'Anguilla',
    value: 'AI'
  },
  {
    title: 'Antarctica',
    value: 'AQ'
  },
  {
    title: 'Antigua and Barbuda',
    value: 'AG'
  },
  {
    title: 'Argentina',
    value: 'AR'
  },
  {
    title: 'Armenia',
    value: 'AM'
  },
  {
    title: 'Aruba',
    value: 'AW'
  },
  {
    title: 'Australia',
    value: 'AU'
  },
  {
    title: 'Austria',
    value: 'AT'
  },
  {
    title: 'Azerbaijan',
    value: 'AZ'
  },
  {
    title: 'Bahamas (the)',
    value: 'BS'
  },
  {
    title: 'Bahrain',
    value: 'BH'
  },
  {
    title: 'Bangladesh',
    value: 'BD'
  },
  {
    title: 'Barbados',
    value: 'BB'
  },
  {
    title: 'Belarus',
    value: 'BY'
  },
  {
    title: 'Belgium',
    value: 'BE'
  },
  {
    title: 'Belize',
    value: 'BZ'
  },
  {
    title: 'Benin',
    value: 'BJ'
  },
  {
    title: 'Bermuda',
    value: 'BM'
  },
  {
    title: 'Bhutan',
    value: 'BT'
  },
  {
    title: 'Bolivia (Plurinational State of)',
    value: 'BO'
  },
  {
    title: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ'
  },
  {
    title: 'Bosnia and Herzegovina',
    value: 'BA'
  },
  {
    title: 'Botswana',
    value: 'BW'
  },
  {
    title: 'Bouvet Island',
    value: 'BV'
  },
  {
    title: 'Brazil',
    value: 'BR'
  },
  {
    title: 'British Indian Ocean Territory (the)',
    value: 'IO'
  },
  {
    title: 'Brunei Darussalam',
    value: 'BN'
  },
  {
    title: 'Bulgaria',
    value: 'BG'
  },
  {
    title: 'Burkina Faso',
    value: 'BF'
  },
  {
    title: 'Burundi',
    value: 'BI'
  },
  {
    title: 'Cabo Verde',
    value: 'CV'
  },
  {
    title: 'Cambodia',
    value: 'KH'
  },
  {
    title: 'Cameroon',
    value: 'CM'
  },
  {
    title: 'Canada',
    value: 'CA'
  },
  {
    title: 'Cayman Islands (the)',
    value: 'KY'
  },
  {
    title: 'Central African Republic (the)',
    value: 'CF'
  },
  {
    title: 'Chad',
    value: 'TD'
  },
  {
    title: 'Chile',
    value: 'CL'
  },
  {
    title: 'China',
    value: 'CN'
  },
  {
    title: 'Christmas Island',
    value: 'CX'
  },
  {
    title: 'Cocos (Keeling) Islands (the)',
    value: 'CC'
  },
  {
    title: 'Colombia',
    value: 'CO'
  },
  {
    title: 'Comoros (the)',
    value: 'KM'
  },
  {
    title: 'Congo (the Democratic Republic of the)',
    value: 'CD'
  },
  {
    title: 'Congo (the)',
    value: 'CG'
  },
  {
    title: 'Cook Islands (the)',
    value: 'CK'
  },
  {
    title: 'Costa Rica',
    value: 'CR'
  },
  {
    title: 'Croatia',
    value: 'HR'
  },
  {
    title: 'Cuba',
    value: 'CU'
  },
  {
    title: 'Curaçao',
    value: 'CW'
  },
  {
    title: 'Cyprus',
    value: 'CY'
  },
  {
    title: 'Czechia',
    value: 'CZ'
  },
  {
    title: "Côte d'Ivoire",
    value: 'CI'
  },
  {
    title: 'Denmark',
    value: 'DK'
  },
  {
    title: 'Djibouti',
    value: 'DJ'
  },
  {
    title: 'Dominica',
    value: 'DM'
  },
  {
    title: 'Dominican Republic (the)',
    value: 'DO'
  },
  {
    title: 'Ecuador',
    value: 'EC'
  },
  {
    title: 'Egypt',
    value: 'EG'
  },
  {
    title: 'El Salvador',
    value: 'SV'
  },
  {
    title: 'Equatorial Guinea',
    value: 'GQ'
  },
  {
    title: 'Eritrea',
    value: 'ER'
  },
  {
    title: 'Estonia',
    value: 'EE'
  },
  {
    title: 'Eswatini',
    value: 'SZ'
  },
  {
    title: 'Ethiopia',
    value: 'ET'
  },
  {
    title: 'Falkland Islands (the) [Malvinas]',
    value: 'FK'
  },
  {
    title: 'Faroe Islands (the)',
    value: 'FO'
  },
  {
    title: 'Fiji',
    value: 'FJ'
  },
  {
    title: 'Finland',
    value: 'FI'
  },
  {
    title: 'France',
    value: 'FR'
  },
  {
    title: 'French Guiana',
    value: 'GF'
  },
  {
    title: 'French Polynesia',
    value: 'PF'
  },
  {
    title: 'French Southern Territories (the)',
    value: 'TF'
  },
  {
    title: 'Gabon',
    value: 'GA'
  },
  {
    title: 'Gambia (the)',
    value: 'GM'
  },
  {
    title: 'Georgia',
    value: 'GE'
  },
  {
    title: 'Germany',
    value: 'DE'
  },
  {
    title: 'Ghana',
    value: 'GH'
  },
  {
    title: 'Gibraltar',
    value: 'GI'
  },
  {
    title: 'Greece',
    value: 'GR'
  },
  {
    title: 'Greenland',
    value: 'GL'
  },
  {
    title: 'Grenada',
    value: 'GD'
  },
  {
    title: 'Guadeloupe',
    value: 'GP'
  },
  {
    title: 'Guam',
    value: 'GU'
  },
  {
    title: 'Guatemala',
    value: 'GT'
  },
  {
    title: 'Guernsey',
    value: 'GG'
  },
  {
    title: 'Guinea',
    value: 'GN'
  },
  {
    title: 'Guinea-Bissau',
    value: 'GW'
  },
  {
    title: 'Guyana',
    value: 'GY'
  },
  {
    title: 'Haiti',
    value: 'HT'
  },
  {
    title: 'Heard Island and McDonald Islands',
    value: 'HM'
  },
  {
    title: 'Holy See (the)',
    value: 'VA'
  },
  {
    title: 'Honduras',
    value: 'HN'
  },
  {
    title: 'Hong Kong',
    value: 'HK'
  },
  {
    title: 'Hungary',
    value: 'HU'
  },
  {
    title: 'Iceland',
    value: 'IS'
  },
  {
    title: 'India',
    value: 'IN'
  },
  {
    title: 'Indonesia',
    value: 'ID'
  },
  {
    title: 'Iran (Islamic Republic of)',
    value: 'IR'
  },
  {
    title: 'Iraq',
    value: 'IQ'
  },
  {
    title: 'Ireland',
    value: 'IE'
  },
  {
    title: 'Isle of Man',
    value: 'IM'
  },
  {
    title: 'Israel',
    value: 'IL'
  },
  {
    title: 'Italy',
    value: 'IT'
  },
  {
    title: 'Jamaica',
    value: 'JM'
  },
  {
    title: 'Japan',
    value: 'JP'
  },
  {
    title: 'Jersey',
    value: 'JE'
  },
  {
    title: 'Jordan',
    value: 'JO'
  },
  {
    title: 'Kazakhstan',
    value: 'KZ'
  },
  {
    title: 'Kenya',
    value: 'KE'
  },
  {
    title: 'Kiribati',
    value: 'KI'
  },
  {
    title: "Korea (the Democratic People's Republic of)",
    value: 'KP'
  },
  {
    title: 'Korea (the Republic of)',
    value: 'KR'
  },
  {
    title: 'Kuwait',
    value: 'KW'
  },
  {
    title: 'Kyrgyzstan',
    value: 'KG'
  },
  {
    title: "Lao People's Democratic Republic (the)",
    value: 'LA'
  },
  {
    title: 'Latvia',
    value: 'LV'
  },
  {
    title: 'Lebanon',
    value: 'LB'
  },
  {
    title: 'Lesotho',
    value: 'LS'
  },
  {
    title: 'Liberia',
    value: 'LR'
  },
  {
    title: 'Libya',
    value: 'LY'
  },
  {
    title: 'Liechtenstein',
    value: 'LI'
  },
  {
    title: 'Lithuania',
    value: 'LT'
  },
  {
    title: 'Luxembourg',
    value: 'LU'
  },
  {
    title: 'Macao',
    value: 'MO'
  },
  {
    title: 'Madagascar',
    value: 'MG'
  },
  {
    title: 'Malawi',
    value: 'MW'
  },
  {
    title: 'Malaysia',
    value: 'MY'
  },
  {
    title: 'Maldives',
    value: 'MV'
  },
  {
    title: 'Mali',
    value: 'ML'
  },
  {
    title: 'Malta',
    value: 'MT'
  },
  {
    title: 'Marshall Islands (the)',
    value: 'MH'
  },
  {
    title: 'Martinique',
    value: 'MQ'
  },
  {
    title: 'Mauritania',
    value: 'MR'
  },
  {
    title: 'Mauritius',
    value: 'MU'
  },
  {
    title: 'Mayotte',
    value: 'YT'
  },
  {
    title: 'Mexico',
    value: 'MX'
  },
  {
    title: 'Micronesia (Federated States of)',
    value: 'FM'
  },
  {
    title: 'Moldova (the Republic of)',
    value: 'MD'
  },
  {
    title: 'Monaco',
    value: 'MC'
  },
  {
    title: 'Mongolia',
    value: 'MN'
  },
  {
    title: 'Montenegro',
    value: 'ME'
  },
  {
    title: 'Montserrat',
    value: 'MS'
  },
  {
    title: 'Morocco',
    value: 'MA'
  },
  {
    title: 'Mozambique',
    value: 'MZ'
  },
  {
    title: 'Myanmar',
    value: 'MM'
  },
  {
    title: 'Namibia',
    value: 'NA'
  },
  {
    title: 'Nauru',
    value: 'NR'
  },
  {
    title: 'Nepal',
    value: 'NP'
  },
  {
    title: 'Netherlands (the)',
    value: 'NL'
  },
  {
    title: 'New Caledonia',
    value: 'NC'
  },
  {
    title: 'New Zealand',
    value: 'NZ'
  },
  {
    title: 'Nicaragua',
    value: 'NI'
  },
  {
    title: 'Niger (the)',
    value: 'NE'
  },
  {
    title: 'Nigeria',
    value: 'NG'
  },
  {
    title: 'Niue',
    value: 'NU'
  },
  {
    title: 'Norfolk Island',
    value: 'NF'
  },
  {
    title: 'Northern Mariana Islands (the)',
    value: 'MP'
  },
  {
    title: 'Norway',
    value: 'NO'
  },
  {
    title: 'Oman',
    value: 'OM'
  },
  {
    title: 'Pakistan',
    value: 'PK'
  },
  {
    title: 'Palau',
    value: 'PW'
  },
  {
    title: 'Palestine, State of',
    value: 'PS'
  },
  {
    title: 'Panama',
    value: 'PA'
  },
  {
    title: 'Papua New Guinea',
    value: 'PG'
  },
  {
    title: 'Paraguay',
    value: 'PY'
  },
  {
    title: 'Peru',
    value: 'PE'
  },
  {
    title: 'Philippines (the)',
    value: 'PH'
  },
  {
    title: 'Pitcairn',
    value: 'PN'
  },
  {
    title: 'Poland',
    value: 'PL'
  },
  {
    title: 'Portugal',
    value: 'PT'
  },
  {
    title: 'Puerto Rico',
    value: 'PR'
  },
  {
    title: 'Qatar',
    value: 'QA'
  },
  {
    title: 'Republic of North Macedonia',
    value: 'MK'
  },
  {
    title: 'Romania',
    value: 'RO'
  },
  {
    title: 'Russian Federation (the)',
    value: 'RU'
  },
  {
    title: 'Rwanda',
    value: 'RW'
  },
  {
    title: 'Réunion',
    value: 'RE'
  },
  {
    title: 'Saint Barthélemy',
    value: 'BL'
  },
  {
    title: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH'
  },
  {
    title: 'Saint Kitts and Nevis',
    value: 'KN'
  },
  {
    title: 'Saint Lucia',
    value: 'LC'
  },
  {
    title: 'Saint Martin (French part)',
    value: 'MF'
  },
  {
    title: 'Saint Pierre and Miquelon',
    value: 'PM'
  },
  {
    title: 'Saint Vincent and the Grenadines',
    value: 'VC'
  },
  {
    title: 'Samoa',
    value: 'WS'
  },
  {
    title: 'San Marino',
    value: 'SM'
  },
  {
    title: 'Sao Tome and Principe',
    value: 'ST'
  },
  {
    title: 'Saudi Arabia',
    value: 'SA'
  },
  {
    title: 'Senegal',
    value: 'SN'
  },
  {
    title: 'Serbia',
    value: 'RS'
  },
  {
    title: 'Seychelles',
    value: 'SC'
  },
  {
    title: 'Sierra Leone',
    value: 'SL'
  },
  {
    title: 'Singapore',
    value: 'SG'
  },
  {
    title: 'Sint Maarten (Dutch part)',
    value: 'SX'
  },
  {
    title: 'Slovakia',
    value: 'SK'
  },
  {
    title: 'Slovenia',
    value: 'SI'
  },
  {
    title: 'Solomon Islands',
    value: 'SB'
  },
  {
    title: 'Somalia',
    value: 'SO'
  },
  {
    title: 'South Africa',
    value: 'ZA'
  },
  {
    title: 'South Georgia and the South Sandwich Islands',
    value: 'GS'
  },
  {
    title: 'South Sudan',
    value: 'SS'
  },
  {
    title: 'Spain',
    value: 'ES'
  },
  {
    title: 'Sri Lanka',
    value: 'LK'
  },
  {
    title: 'Sudan (the)',
    value: 'SD'
  },
  {
    title: 'Suriname',
    value: 'SR'
  },
  {
    title: 'Svalbard and Jan Mayen',
    value: 'SJ'
  },
  {
    title: 'Sweden',
    value: 'SE'
  },
  {
    title: 'Switzerland',
    value: 'CH'
  },
  {
    title: 'Syrian Arab Republic',
    value: 'SY'
  },
  {
    title: 'Taiwan (Province of China)',
    value: 'TW'
  },
  {
    title: 'Tajikistan',
    value: 'TJ'
  },
  {
    title: 'Tanzania, United Republic of',
    value: 'TZ'
  },
  {
    title: 'Thailand',
    value: 'TH'
  },
  {
    title: 'Timor-Leste',
    value: 'TL'
  },
  {
    title: 'Togo',
    value: 'TG'
  },
  {
    title: 'Tokelau',
    value: 'TK'
  },
  {
    title: 'Tonga',
    value: 'TO'
  },
  {
    title: 'Trinidad and Tobago',
    value: 'TT'
  },
  {
    title: 'Tunisia',
    value: 'TN'
  },
  {
    title: 'Turkey',
    value: 'TR'
  },
  {
    title: 'Turkmenistan',
    value: 'TM'
  },
  {
    title: 'Turks and Caicos Islands (the)',
    value: 'TC'
  },
  {
    title: 'Tuvalu',
    value: 'TV'
  },
  {
    title: 'Uganda',
    value: 'UG'
  },
  {
    title: 'Ukraine',
    value: 'UA'
  },
  {
    title: 'United Arab Emirates (the)',
    value: 'AE'
  },
  {
    title: 'United Kingdom of Great Britain and Northern Ireland (the)',
    value: 'GB'
  },
  {
    title: 'United States Minor Outlying Islands (the)',
    value: 'UM'
  },
  {
    title: 'United States of America (the)',
    value: 'US'
  },
  {
    title: 'Uruguay',
    value: 'UY'
  },
  {
    title: 'Uzbekistan',
    value: 'UZ'
  },
  {
    title: 'Vanuatu',
    value: 'VU'
  },
  {
    title: 'Venezuela (Bolivarian Republic of)',
    value: 'VE'
  },
  {
    title: 'Viet Nam',
    value: 'VN'
  },
  {
    title: 'Virgin Islands (British)',
    value: 'VG'
  },
  {
    title: 'Virgin Islands (U.S.)',
    value: 'VI'
  },
  {
    title: 'Wallis and Futuna',
    value: 'WF'
  },
  {
    title: 'Western Sahara',
    value: 'EH'
  },
  {
    title: 'Yemen',
    value: 'YE'
  },
  {
    title: 'Zambia',
    value: 'ZM'
  },
  {
    title: 'Zimbabwe',
    value: 'ZW'
  },
  {
    title: 'Åland Islands',
    value: 'AX'
  }
]

export default {
  title: 'Artist',
  name: 'artist',
  type: 'document',
  icon: () => <Users />,
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string'
    },
    {
      title: 'URL Slug',
      name: 'slug',
      type: 'slug',
      description: '(required)',
      options: {
        source: 'name',
        maxLength: 96
      },
      validation: Rule => Rule.required()
    },
    customImage({ name: 'photo', hasDisplayOptions: false }),
    {
      title: 'Country',
      name: 'country',
      type: 'string',
      options: {
        list: countryList
      }
    },
    {
      title: 'Short text',
      name: 'shortText',
      type: 'text'
    },
    {
      title: 'Customization',
      name: 'styling',
      type: 'slideStyling'
    },
    {
      title: 'SEO / Share Settings',
      name: 'seo',
      type: 'seo'
    }
  ],
  preview: {
    select: {
      title: 'title',
      slug: 'slug',
      country: 'country'
    },
    prepare({ title = 'Untitled', slug = {}, country = '' }) {
      const path = `/artists/${slug.current}`
      return {
        title: `${title}${country ? ` (${country})` : ''}`,
        subtitle: slug.current ? path : '(missing slug)'
      }
    }
  }
}
