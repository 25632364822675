import React from 'react'

import { ProductAdd } from '@components/product'

const ProductActions = ({ activeVariant, children, className }) => {
  return (
    <div className="product--actions">
      <ProductAdd
        product={activeVariant}
        quantity={1}
        className={
          className ? className : 'btn is-primary is-large is-flex w-full'
        }
      >
        {children}
      </ProductAdd>
    </div>
  )
}

export default ProductActions
