import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import Collection from '@components/collection'
import Introduction from '@components/introduction'
import Layout from '@components/layout'

const ProductsPage = ({ data }) => {
  const { site, page } = data
  const router = useRouter()

  return (
    <Layout site={site} page={page}>
      <Head>
        <link
          rel="canonical"
          href={`${site.rootDomain}/${router.asPath.split('?')[0]}`}
        />
      </Head>
      <Introduction page={page} hasSidebar={true} />
      <Collection page={page} />
    </Layout>
  )
}

export default ProductsPage
